<template>
  <a-card title="区域套餐管理">
    <a-form :label-col="{ span: 2 }" :wrapper-col="wrapperCol">
      <a-form-model-item label="套餐名称" prop="name">
        <span v-if="formData.type==1">{{formData.name}}</span>
        <a-input v-else style="width:400px" placeholder="请输入套餐名称" v-model="formData.name" />
      </a-form-model-item>
      <a-form-model-item v-if="formData.type!=1" prop="adcode" label="面向区域" name="adcode">
        <region-tree-select v-if="isLoadingOver || !$route.query.id" style="width:400px;" @getNodes="getAreaNodes" :value.sync="region_tree_data" />
      </a-form-model-item>
    </a-form>
    <div class="flex flex-wrap" style="margin-left:50px">
      <div
        class="package-class"
        v-for="(item, index) in formData.recharge_activity_list"
        :key="index"
      >
        <a-icon @click="handlerDelete(index)" type="close" class="cursor-pointer absolute right-4" />
        <a-form :form="formData" class="mt-2">
          <a-form-item class="mb-2" :label-col="{ span: 3 }" label="充值金额">
            <span class="mr-2">充</span>
            <a-input-number :step="0.01" placeholder="金额" v-model="item.price" />
            <span class="ml-2">元，</span>
            <span class="ml-4 mr-2">送</span>
            <a-input-number :step="0.01" placeholder="金额" v-model="item.give" />
            <span class="ml-2">元</span>
          </a-form-item>
          <a-form-item class="mb-2" :label-col="{ span: 3 }" label="赠积分">
            <a-input-number placeholder="积分" v-model="item.gift_integral" />
            <span style="margin-left:5px">分</span>
          </a-form-item>
          <a-form-item class="mb-2" :label-col="{ span: 3 }" label="赠优惠券">
            <div class="item-class">
              <a-select
                v-model="item.gift_coupon_id_list"
                mode="tags"
                style="margin-right: 10px"
                placeholder="请选择优惠券"
                :open="false"
                disabled
                @deselect="handlerCouponChange"
              >
                <a-select-option
                  v-for="el in couponList[index]"
                  :key="el.coupon_id"
                  :value="el.coupon_id.toString()"
                >{{ el.coupon_name }}</a-select-option>
              </a-select>
              <a-button @click="handlerAddCoupons(index)" type="link">编辑</a-button>
            </div>
          </a-form-item>
          <a-form-item :label-col="{ span: 3 }" label="发布状态">
            <a-select v-model="item.status" style="width:170px;" placeholder="状态">
              <a-select-option v-for="id in Object.keys(statusList)"
                :value="Number(id)" :key="id">{{ formatStatus(id) }}</a-select-option>
            </a-select>
          </a-form-item>
        </a-form>
      </div>
    </div>
    <div class="ml-8 mt-5">
      <a-button @click="handlerAddPackage" type="link">+ 添加充值套餐</a-button>
      <!-- <a-button class="ml-5" @click="handlerDeleteCoupons" type="primary">- 删除充值套餐</a-button> -->
    </div>

    <div class="submit-class pl-20">
      <a-button style="margin: 0 auto" type="primary" @click="handlerSubmit">提交</a-button>
    </div>

    <AddCouponModal
      v-if="isShowAddCoupon"
      :show.sync="isShowAddCoupon"
      :selectKeys="selectCouponKeys"
      :selectRows="couponList[activeFavorable]"
      @ok="handlerOkCoupon"
    />
  </a-card>
</template>

<script>
import RegionTreeSelect from "@/components/region-tree-select"
import {
  getRechargeDetail,
  addRecharge,
  updateRecharge,
} from "@/api/activity/vip-card.js"
import AddCouponModal from "@/components/select-modal/add-coupon.vue"
import { statusList, formatStatus } from "./columns"

export default {
  components: {
    RegionTreeSelect,
    AddCouponModal,
  },
  data() {
    return {
      statusList,
      formatStatus,

      cityList: [],
      activeFavorable: 0,
      isShowAddCoupon: false,
      isLoadingOver: false,
      labelCol: { span: 4 },
      wrapperCol: { span: 20 },
      formData: {
        delivery_region_data: '',
        name: "",
        type: "2",
        recharge_activity_list: [
          {
            gift_coupon_list: [
              {
                coupon_id: "",
                coupon_name: "",
              },
            ],
            gift_coupon_id_list: [],
            gift_coupon_ids: '',
            // gift_coupon_ids: [],
            gift_integral: 0,
            denomination: "",
            price: "",
            give: "",
            recharge_activity_id: "",
          },
        ],
      },
      region_tree_data: [],

      packageCount: 1,
      couponsCount: 1,
      selectKeys: [],
      selectCouponKeys: [],
      couponList: [[]],
    }
  },
  mounted() {
    if (this.$route.query.id) {
      this.initData(this.$route.query.id)
    }
  },
  methods: {
    async initData(id) {
      const { code, data } = await getRechargeDetail({ id: id })
      if (code == 0) {
        data.recharge_activity_list.map(el=>{
          el.give = Math.round((Number(el.denomination) - Number(el.price)) * 100) / 100
        })

        this.formData = data
        this.isLoadingOver = true
        this.formData.recharge_activity_list.map((el, i) => {
          this.couponList[i] = el.gift_coupon_list
          el.gift_coupon_id_list = el.gift_coupon_list.map((o) =>
            o.coupon_id.toString()
          )
          return el
        })

        // 把一维数组转成树状结构
        let _tmpHaxe = {} // 排重使用
        const treeData = []
        data.delivery_region_list.map(el=>{
          const reduce_adcode = String(el.adcode).replace(/0*$/, "")
          const p_adcode = String(el.adcode).substr(0,2) + "0000000000"
          const c_adcode = String(el.adcode).substr(0,4) + "00000000"
          const a_adcode = String(el.adcode).substr(0,6) + "000000"
          let provinceData
          let cityData
          let areaData
          // 省
          if(_tmpHaxe[p_adcode]){
            provinceData = _tmpHaxe[p_adcode]
          }else{
            provinceData = {
              type: 1,
              adcode: p_adcode,
              selectChildren: []
            }
            treeData.push(provinceData)
            _tmpHaxe[p_adcode] = provinceData
          }
          // 市
          if(reduce_adcode.length>2){
            if(_tmpHaxe[c_adcode]){
              cityData = _tmpHaxe[c_adcode]
            }else{
              cityData = {
                type: 2,
                adcode: c_adcode,
                selectChildren: []
              }
              provinceData.selectChildren.push(cityData)
              _tmpHaxe[c_adcode] = cityData
            }
            // 区县
            if(reduce_adcode.length>4){
              areaData = {
                type: 3,
                adcode: a_adcode,
                selectChildren: []
              }
              cityData.selectChildren.push(areaData)
            }
          }
        })
        _tmpHaxe = null

        this.region_tree_data = treeData
      }
    },

    getAreaNodes(nodes){
      this.region_tree_data = nodes
    },

    handlerAddPackage() {
      this.formData.recharge_activity_list.push({
        denomination: "",
        price: "",
        give: "",
        gift_integral: 0,
        gift_coupon_id_list: [],
        gift_coupon_list: [],
        recharge_activity_id: "0",
        status: undefined,
      })
    },
    handlerDeleteCoupons() {
      this.formData.recharge_activity_list.pop()
    },
    //删除
    handlerDelete(i) {
      this.formData.recharge_activity_list.splice(i, 1)
      this.couponList.splice(i, 1)
    },

    handlerAddCoupons(e) {
      this.activeFavorable = e
      this.isShowAddCoupon = true
      if (this.couponList[e]) {
        this.selectCouponKeys = this.couponList[e].map((el) => el.coupon_id)
      } else {
        this.selectCouponKeys = []
      }
    },
    handlerOkCoupon(rows) {
      console.log("handlerOkCoupon", rows)

      this.couponList[this.activeFavorable] = rows
      this.formData.recharge_activity_list[
        this.activeFavorable
      ].gift_coupon_id_list = rows.map((el) => {
        return el.coupon_id.toString()
      })
    },
    handlerCouponChange(val) {
      this.formData.recharge_activity_list[
        this.activeFavorable
      ].gift_coupon_id_list = this.formData.recharge_activity_list[
        this.activeFavorable
      ].gift_coupon_id_list.filter((el) => el != val)
      this.couponList[this.activeFavorable] = this.couponList.filter(
        (el) => el.coupon_id != val
      )
    },
    async handlerSubmit() {

      const status_err = this.formData.recharge_activity_list.some(item=>{
        return !this.statusList[item.status]
      })
      if(status_err){
        this.$message.info("请选择状态")
        return
      }

      if (this.$route.query.id) { // 修改
        this.formData.recharge_activity_list.map((item) => {
          item.denomination = Math.round((Number(item.price) + (Number(item.give)||0)) * 100) / 100
          item.gift_coupon_ids = item.gift_coupon_id_list.join(",")
        })

        const { code } = await updateRecharge({
          id: this.$route.query.id,
          name: this.formData.name,
          recharge_activity_data: JSON.stringify(this.formData.recharge_activity_list),
          delivery_region_data:  JSON.stringify(this.region_tree_data)
        })
        if (code == 0) {
          this.$message.success("提交成功")
          setTimeout(() => {
            this.$router.go(-1)
          }, 1500)
        }
      } else { // 添加
        this.formData.recharge_activity_list.map((item) => {
          item.denomination = Math.round((Number(item.price) + (Number(item.give)||0)) * 100) / 100
          item.gift_coupon_ids = item.gift_coupon_id_list.join(",")
        })

        const { code } = await addRecharge({
          name: this.formData.name,
          type: 2,
          recharge_activity_data: JSON.stringify(this.formData.recharge_activity_list),
          delivery_region_data: JSON.stringify(this.region_tree_data)
        })
        if (code == 0) {
          this.$message.success("提交成功")
          setTimeout(() => {
            this.$router.go(-1)
          }, 1500)
        }
      }
    },
  },
}
</script>

<style lang="less" scoped>
.package-class {
  position: relative;
  padding: 10px 20px 0;
  margin-top: 10px;
  margin-right: 10px;
  width: 600px;
  background-color: #f0f2f5;
  border-radius: 5px;
}
.item-class {
  display: flex;
  justify-items: center;
  align-items: center;
}
.submit-class {
  width: 100%;
  margin-top: 30px;
}
</style>
